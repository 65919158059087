@if (resource) {
  <div class="slide-over-content-header">
    <div class="title-and-tags-container">
      <h3
        [class.has-labels]="resource?.resourceLabels?.length"
        class="mt-0"
      >
        {{ resource.name }}
      </h3>
      @if (resource?.resourceLabels?.length) {
        <dpa-custom-tag
          [bgColorClass]="resourceTagBgColorClass"
          [text]="resource.customTagText"
          class="ml-x1"
        ></dpa-custom-tag>
        <dpa-from-now
          [timestamp]="resource.modifiedAt"
          [formatString]="modifiedAtLabel"
          class="p5"
        ></dpa-from-now>
      }
      <div class="mt-x2">
        @for (tag of resource.flatTags; track tag) {
          <span class="label">
            {{ tag }}
          </span>
        }
      </div>
    </div>
    <ng-template
      [ngTemplateOutlet]="usageCountTemplate"
      [ngTemplateOutletContext]="{ usageCount: resource.usageCount }"
    ></ng-template>
    <dpa-tooltip [tooltipText]="isButtonDisabled ? buttonDisabledTooltipText : ''">
      <button
        [disabled]="isButtonDisabled"
        (click)="selectResource.emit(resource)"
        class="btn btn-primary mt-0 mr-0 mb-0"
      >
        {{ buttonText | translate }}
      </button>
    </dpa-tooltip>
  </div>
  <p>{{ resource.description }}</p>
}
<ng-template
  #usageCountTemplate
  let-usageCount="usageCount"
>
  @if (usageCount) {
    <div>
      {{ 'MARKETPLACE.USED' | translate }}
      <dpa-tooltip
        [attr.aria-label]="'MARKETPLACE.USED_COUNT_MESSAGE' | translate: { count: usageCount }"
        [tooltipTemplate]="tooltipTemplate"
        aria-haspopup="true"
        class="tooltip tooltip-xs tooltip-top-right"
        role="tooltip"
      >
        <span class="label label-light-blue">
          {{ usageCount | shorthandNumber }}
        </span>
      </dpa-tooltip>
      <ng-template #tooltipTemplate>
        <div [innerHTML]="'MARKETPLACE.USED_COUNT_MESSAGE' | translate: { count: usageCount | number }"></div>
      </ng-template>
    </div>
  }
</ng-template>
