<clr-input-container [class.rich-text-editor]="metadata?.presentationType === MetaFormFieldPresentationType.RICH_TEXT_EDITOR">
  <label>
    {{ metadata?.label ?? name }}
  </label>
  @if (metadata?.presentationType !== MetaFormFieldPresentationType.RICH_TEXT_EDITOR) {
    <input
      clrInput
      [attr.placeholder]="metadata?.placeholderValue"
      [formControl]="formControl"
      [readOnly]="true"
      type="text"
    />
  } @else {
    <quill-view-html [content]="formControl.value"></quill-view-html>
  }
</clr-input-container>
