@if (showConfirmEnableModal$ | async) {
  <dpa-modal
    *ngLet="(feature$ | async)?.toUpperCase() as feature"
    [modalOpen]="true"
    [modalSize]="ModalSize.LG"
    (onModalClose)="onEnableFeatureCancelClick()"
  >
    <dpa-modal-title>
      <!-- Possible Values
      FEATURE.OPT_IN.DATA_SEGMENTATION_TITLE
      -->
      {{ 'FEATURE.OPT_IN.' + feature + '_TITLE' | translate }}
    </dpa-modal-title>
    <div class="modal-body">
      <dpa-alert-banner [target]="AlertBannerTarget.MODAL"></dpa-alert-banner>
      <!-- Possible Values
      FEATURE.OPT_IN.DATA_SEGMENTATION_BODY
      -->
      <div [innerHTML]="'FEATURE.OPT_IN.' + feature + '_BODY' | translate: { url: supportLink }"></div>
      <ng-container *ngTemplateOutlet="additionalContentTemplate"></ng-container>
    </div>
    <div class="modal-footer">
      @if (sendEmailApplicable) {
        <clr-checkbox-wrapper class="mt-x1 ml-x1">
          <input
            clrCheckbox
            [(ngModel)]="sendEmail"
            type="checkbox"
            name="sendEmail"
            id="sendEmail"
          />
          <label for="sendEmail">
            {{ 'FEATURE.OPT_IN.SEND_EMAIL_TO_ALL_ADMINS' | translate: { admins: applicableAdminLabel } }}
          </label>
        </clr-checkbox-wrapper>
      }
      <div class="button-group">
        <button
          (click)="onEnableFeatureCancelClick()"
          class="btn btn-link"
          type="button"
        >
          {{ 'COMMON_ACTIONS.CANCEL' | translate }}
        </button>
        <button
          [clrLoading]="loading$ | async"
          (click)="enableFeature()"
          class="btn btn-primary"
          type="button"
        >
          {{ 'COMMON_ACTIONS.ACTIVATE' | translate }}
        </button>
      </div>
    </div>
  </dpa-modal>
}
