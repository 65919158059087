@if (!isMinimized || editing) {
  <div
    [class.content-tooltip-container]="isMinimized"
    [class.minimized]="isMinimized"
    [tabIndex]="isMinimized ? '0' : null"
    [attr.role]="isMinimized ? 'button' : null"
    class="dashboard-filter-container"
  >
    @if (showTrendDateRange) {
      <div class="date-range-container">
        <div class="filter-icon-container mr-x2">
          <dpa-tooltip
            [tooltipText]="'COMMON_MESSAGES.DATE_RANGE_HELP_TEXT' | translate"
            [focusEnabled]="true"
          >
            <cds-icon shape="calendar"></cds-icon>
          </dpa-tooltip>
        </div>
        <div class="date-range">
          <dpa-trend-date-range
            [readonly]="isTrendDateRangeReadonly"
            [trendDateRange]="trendDateRange"
            [maxCustomRange]="maxCustomRange"
            [customTimePeriodHelpText]="customTimePeriodHelpText"
            (trendDateRangeUpdated)="updateTrendDateRange($event)"
          ></dpa-trend-date-range>
        </div>
        @if (!isTrendDateRangeReadonly) {
          <div class="date-range-actions">
            <button
              [disabled]="!canResetDateRange()"
              (click)="resetDateRange()"
              class="btn btn-link btn-sm"
            >
              {{ 'COMMON_ACTIONS.RESET' | translate }}
            </button>
          </div>
        }
      </div>
    }
    @if (showFilters) {
      <div
        [class.border-top]="showTrendDateRange"
        class="filter-group-container"
      >
        <dpa-filter-group
          [applyCardStyling]="false"
          [isCollapsed]="!editing && editable"
          [group]="editing ? ruleGroupEdit : ruleGroup"
          [columnsByName]="columnsByName"
          [showThreeColumnFilter]="showThreeColumnFilter"
          [alwaysShowKeySelector]="false"
          [showIncludesAllText]="showIncludesAllText"
          [hideEmptyRules]="hideEmptyRules"
          [suggestionCategory]="suggestionCategory"
          [suggestionFilterBys]="suggestionFilterBys"
          [isCrossCategory]="isCrossCategory"
          [showLess]="true"
          [readOnly]="!editable"
          (rulesChanged)="onRuleChange($event)"
          (isCollapsedChange)="onCollapsedChange($event)"
        ></dpa-filter-group>
      </div>
      @if (editing) {
        <div class="filter-group-footer">
          <button
            [disabled]="!canResetFilter()"
            (click)="resetFilter()"
            class="btn btn-link btn-sm"
          >
            {{ 'COMMON_ACTIONS.RESET' | translate }}
          </button>
          <button
            (click)="cancelFilter()"
            class="btn btn-link btn-sm"
          >
            {{ 'COMMON_ACTIONS.CANCEL' | translate }}
          </button>
          <button
            (click)="applyFilter()"
            class="btn btn-primary btn-sm"
          >
            {{ 'COMMON_ACTIONS.APPLY' | translate }}
          </button>
        </div>
      }
    }
  </div>
}
