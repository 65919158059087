@if ((isAutomationEnabled$ | async) === false) {
  <dpa-intro-feature-alert
    [introType]="IntroType.AUTOMATION"
    [alertText]="'FEATURE.AUTOMATIONS_NOT_ENABLED_ALERT' | translate"
  ></dpa-intro-feature-alert>
}
<dpa-template-search
  [templateType]="templateType"
  [customCardBlock]="customTemplate"
  [searchExact]="true"
  [searchOn]="'label'"
  [tags]="tags"
  [templates]="templates$ | async"
  [templateTableColumns]="tableColumns"
  [showCustomContentTemplate]="showCustomButton"
  [bookmarksEnabled]="false"
  [tableName]="'COMMON_MESSAGES.WORKFLOW_TEMPLATES_TABLE' | translate"
  [actions]="actions"
  (clickCustomTemplate)="onClickCustomTemplate()"
></dpa-template-search>

<ng-template
  #customTemplate
  let-template="template"
>
  <dpa-automation-trigger-action
    [automationTemplate]="template"
    [isTemplate]="true"
    class="action-icons-list"
  ></dpa-automation-trigger-action>
</ng-template>
