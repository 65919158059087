@if (duplicateWidgetDialogOpen$ | async; as duplicateWidgetDialogOpen) {
  <dpa-modal
    [modalOpen]="duplicateWidgetDialogOpen"
    (onModalClose)="closeModal()"
  >
    <dpa-modal-title>
      {{ 'DASHBOARD_ACTIONS.DUPLICATE_A_WIDGET' | translate }}
    </dpa-modal-title>
    <div class="modal-body">
      <div class="container">
        @if (duplicateWidgetDialogOpen) {
          <dpa-alert-banner [target]="AlertBannerTarget.MODAL"></dpa-alert-banner>
        }
        <form
          [formGroup]="duplicateWidgetForm"
          class="clr-form clr-form-horizontal"
        >
          <span class="clr-required-mark">{{ 'COMMON_MESSAGES.REQUIRED' | translate }}</span>
          <div class="clr-form-control form-group">
            <label
              class="clr-control-label clr-required-mark"
              for="widgetName"
            >
              {{ 'DASHBOARD_ACTIONS.WIDGET_TITLE' | translate }}
            </label>
            <div
              [class.clr-error]="!duplicateWidgetForm.controls.name.valid"
              class="clr-control-container"
            >
              <div class="clr-input-wrapper">
                <input
                  required
                  type="text"
                  class="clr-input"
                  id="widgetName"
                  formControlName="name"
                />
              </div>
              <span class="clr-subtext">
                {{ 'FORM_VALIDATION.REQUIRED_FIELD' | translate }}
              </span>
            </div>
          </div>
          <div class="clr-form-control form-group">
            <label
              for="widgetDescription"
              class="clr-control-label"
            >
              {{ 'DASHBOARD_ACTIONS.WIDGET_DESCRIPTION' | translate }}
            </label>
            <div class="clr-textarea-wrapper">
              <textarea
                id="widgetDescription"
                formControlName="description"
                class="clr-textarea textarea-widget-description"
              ></textarea>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="modal-footer">
      <button
        (click)="closeModal()"
        class="btn btn-outline"
      >
        {{ 'COMMON_ACTIONS.CANCEL' | translate }}
      </button>
      <button
        [disabled]="duplicateWidgetForm.get('name').invalid"
        (click)="confirmDuplicateWidget(activeWidget, duplicateWidgetForm.value.name)"
        class="btn btn-primary"
      >
        {{ 'COMMON_ACTIONS.SAVE' | translate }}
      </button>
    </div>
  </dpa-modal>
}

@if (deleteWidgetDialogOpen$ | async; as deleteWidgetDialogOpen) {
  <dpa-modal
    [modalOpen]="deleteWidgetDialogOpen"
    (onModalClose)="closeModal()"
  >
    <dpa-modal-title>
      {{ 'DASHBOARD_ACTIONS.DELETE_WIDGET' | translate }}
    </dpa-modal-title>
    <div class="modal-body">
      @if (dashboard?.shareCount > 0) {
        <clr-alert
          clrAlertType="warning"
          [clrAlertClosable]="false"
        >
          <clr-alert-item class="alert-item">
            <span class="alert-text">
              {{ 'DASHBOARD_ACTIONS.DASHBOARD_DELETE_SHARE_WARNING_MSG' | translate: { count: dashboard.shareCount } }}
            </span>
          </clr-alert-item>
        </clr-alert>
      }
      <p>
        {{ 'DASHBOARD_ACTIONS.DELETE_WIDGET_CONFIRM_MSG' | translate }}
      </p>
    </div>
    <div class="modal-footer">
      <button
        (click)="closeModal()"
        class="btn btn-outline"
      >
        {{ 'COMMON_ACTIONS.CANCEL' | translate }}
      </button>
      <button
        (click)="confirmDeleteWidget()"
        class="btn btn-danger"
      >
        {{ 'COMMON_ACTIONS.DELETE' | translate }}
      </button>
    </div>
  </dpa-modal>
}

@if (renameWidgetDialogOpen$ | async; as renameWidgetDialogOpen) {
  <dpa-modal
    [modalOpen]="renameWidgetDialogOpen"
    (onModalClose)="closeModal()"
  >
    <dpa-modal-title>
      {{ 'DASHBOARD_ACTIONS.RENAME_A_WIDGET' | translate }}
    </dpa-modal-title>
    <div class="modal-body">
      <div class="container">
        @if (renameWidgetDialogOpen) {
          <dpa-alert-banner
            [target]="AlertBannerTarget.MODAL"
            class="bad-request-alert"
          ></dpa-alert-banner>
        }
        <form
          [formGroup]="renameWidgetForm"
          class="clr-form clr-form-horizontal"
        >
          <span class="clr-required-mark">{{ 'COMMON_MESSAGES.REQUIRED' | translate }}</span>
          <div class="clr-form-control form-group">
            <label
              class="clr-control-label clr-required-mark"
              for="widgetName"
            >
              {{ 'DASHBOARD_ACTIONS.WIDGET_TITLE' | translate }}
            </label>
            <div
              [class.clr-error]="!renameWidgetForm.controls.name.valid"
              class="clr-control-container"
            >
              <div class="clr-input-wrapper">
                <input
                  required
                  type="text"
                  class="clr-input"
                  id="widgetName"
                  formControlName="name"
                />
              </div>
              <span class="clr-subtext">
                {{ 'FORM_VALIDATION.REQUIRED_FIELD' | translate }}
              </span>
            </div>
          </div>
          <div class="clr-form-control form-group">
            <label
              for="widgetDescription"
              class="clr-control-label"
            >
              {{ 'DASHBOARD_ACTIONS.WIDGET_DESCRIPTION' | translate }}
            </label>
            <div class="clr-textarea-wrapper">
              <textarea
                id="widgetDescription"
                formControlName="description"
                class="clr-textarea textarea-widget-description"
              ></textarea>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="modal-footer">
      <button
        (click)="closeModal()"
        class="btn btn-outline"
      >
        {{ 'COMMON_ACTIONS.CANCEL' | translate }}
      </button>
      <button
        [disabled]="renameWidgetForm.get('name').invalid"
        (click)="confirmRenameWidget(activeWidget, renameWidgetForm.value.name, renameWidgetForm.value.description)"
        class="btn btn-primary"
      >
        {{ 'COMMON_ACTIONS.SAVE' | translate }}
      </button>
    </div>
  </dpa-modal>
}

@if (infoWidgetDialogOpen$ | async; as infoWidgetDialogOpen) {
  <dpa-modal
    [modalOpen]="infoWidgetDialogOpen"
    (onModalClose)="closeModal()"
  >
    <dpa-modal-title>
      {{ activeWidget?.name }}
    </dpa-modal-title>
    <div class="modal-body">
      @if (categoryLabel$ | async; as categoryLabel) {
        <div class="clr-row p-x1">
          <div class="clr-col-3">{{ 'COMMON_MESSAGES.CATEGORY' | translate }}:</div>
          <div class="clr-col-9 pl-x2">
            {{ categoryLabel }}
          </div>
        </div>
      }
      <div class="clr-row p-x1">
        <div class="clr-col-3">{{ 'COMMON_MESSAGES.DESCRIPTION' | translate }}:</div>
        <div class="clr-col-9 pl-x2">
          {{ activeWidget?.description }}
        </div>
      </div>
      <div class="clr-row p-x1">
        <div class="clr-col-3">{{ 'COMMON_MESSAGES.TYPE' | translate }}:</div>
        <div class="clr-col-9 pl-x2">
          {{ 'WIDGET_CUSTOMIZE.WIDGET_CHART_TREND_MODE_' + activeWidget?.trend?.trendDefinition?.trendMode | translate }}
        </div>
      </div>
      <div class="clr-row p-x1">
        <div class="clr-col-3">{{ 'LIST_VIEW.COLUMNS.CREATED_BY' | translate }}:</div>
        <div class="clr-col-9 pl-x2">
          {{ activeWidget?.createdByDetails | userInfo }}
        </div>
      </div>
      <div class="clr-row p-x1">
        <div class="clr-col-3">{{ 'COMMON_MESSAGES.DATE_CREATED' | translate }}:</div>
        <div class="clr-col-9 pl-x2">
          {{ activeWidget?.createdAt | formattedDate: DateTimeFormat.MOMENT_MEDIUM_DATETIME_FORMAT }}
        </div>
      </div>
      <div class="clr-row p-x1">
        <div class="clr-col-3">{{ 'COMMON_MESSAGES.DATE_MODIFIED' | translate }}:</div>
        <div class="clr-col-9 pl-x2">
          {{ activeWidget?.modifiedAt | formattedDate: DateTimeFormat.MOMENT_MEDIUM_DATETIME_FORMAT }}
        </div>
      </div>
    </div>
  </dpa-modal>
}

@if (editThemeWidgetModalOpen$ | async) {
  <dpa-edit-theme-modal></dpa-edit-theme-modal>
}
