@if (isIncrementalLoadingEnabled$ | async) {
  @if (showAsSpinner) {
    <dpa-tooltip
      [tooltipText]="tooltipText | translate"
      [focusEnabled]="true"
      role="tooltip"
    >
      @switch (status) {
        @case (IncrementalLoadingResponseTrendStatus.INPROGRESS) {
          <span>
            <span class="spinner spinner-sm in-progress-indicator"></span>
            <span class="loading-text mr-1">{{ 'INCREMENTAL_LOADING.IN_PROGRESS' | translate }}</span>
          </span>
        }
        @case (IncrementalLoadingResponseTrendStatus.COMPLETED_WITH_SUCCESS) {
          <cds-icon
            class="success-indicator"
            shape="check-circle"
            status="success"
            size="24"
          ></cds-icon>
        }
        @case (IncrementalLoadingResponseTrendStatus.COMPLETED_WITH_FAILURE) {
          <cds-icon
            class="failure-indicator"
            shape="exclamation-circle"
            status="danger"
            size="24"
          ></cds-icon>
        }
      }
    </dpa-tooltip>
  }
  @if (showAsProgressBar && status === IncrementalLoadingResponseTrendStatus.INPROGRESS) {
    <div class="progress loop">
      <progress></progress>
    </div>
  }
}
