@if (availableColumns$ | async; as availableColumns) {
  <dpa-key-selector-search
    [showReset]="false"
    [showColumnsFromInput]="true"
    [allColumns]="availableColumns"
    [selectedColumn]="selectedColumn$ | async"
    [isCrossCategory]="isCrossCategory"
    [placeholderTextKey]="'DASHBOARD_ACTIONS.SELECT_A_GROUP_BY'"
    (columnChange)="onSelectColumn($event)"
  ></dpa-key-selector-search>
}
