<dpa-modal
  [modalOpen]="isOpen"
  [modalSize]="ModalSize.LG"
  (onModalClose)="modalClose.emit()"
>
  <dpa-modal-title class="clr-flex-items-sm-middle">
    <span>
      {{ 'CONNECTIONS.EDIT_CONNECTION' | translate: { name: connector?.name } }}
    </span>
  </dpa-modal-title>
  <div class="modal-body setup-modal-body">
    <dpa-alert-banner
      [target]="AlertBannerTarget.MODAL"
      class="bad-request-alert mb-x2"
    ></dpa-alert-banner>
    <dpa-accordion>
      <div
        dpaAccordionRow
        [title]="'CUSTOM_CONNECTION.AUTHENTICATION_DETAILS' | translate"
        [expanded]="true"
      >
        <ng-template dpaAccordionRowContent>
          <clr-alert
            clrAlertType="info"
            [clrAlertClosable]="false"
            class="credentials-setup-alert"
          >
            <clr-alert-item class="alert-item">
              <span class="alert-text">
                {{ 'CONNECTIONS.CLICK_HERE_FOR_MORE_INFO_SETUP' | translate }}
              </span>
              <div class="alert-actions">
                <a
                  [href]="connector?.setupInfoUrl"
                  target="_blank"
                  rel="noopener"
                  class="alert-action"
                >
                  {{ 'CONNECTIONS.MORE_INFORMATION' | translate }}
                </a>
              </div>
            </clr-alert-item>
          </clr-alert>
          <span class="clr-required-mark">{{ 'COMMON_MESSAGES.REQUIRED' | translate }}</span>
          @if (connectorConfigSchema && connectorConfig?.configData) {
            <dpa-dynamic-form
              [jsonSchema]="connectorConfigSchema?.jsonSchema"
              [metadata]="connectorConfigSchema?.metadata"
              [formValues]="formValues"
              (formGroupChange)="configForm = $event"
            ></dpa-dynamic-form>
          } @else {
            <div class="spinner-container-centered">
              <span class="spinner"></span>
            </div>
          }
        </ng-template>
      </div>
    </dpa-accordion>
  </div>

  <div class="modal-footer">
    <div class="clr-row clr-flex-items-sm-middle">
      @if (isLoading) {
        <div class="spinner spinner-inline"></div>
      }
      <button
        (click)="modalClose.emit()"
        type="button"
        class="btn btn-outline mr-2"
      >
        {{ 'COMMON_ACTIONS.CANCEL' | translate }}
      </button>
      <button
        [disabled]="!configForm?.valid"
        [clrLoading]="isLoading"
        (click)="isConfirmModalOpen = true"
        type="button"
        class="btn btn-primary"
      >
        {{ 'COMMON_ACTIONS.SAVE' | translate }}
      </button>
    </div>
  </div>
</dpa-modal>

@if (isConfirmModalOpen) {
  <dpa-modal
    [modalOpen]="isConfirmModalOpen"
    (onModalClose)="modalClose.emit()"
  >
    <dpa-modal-title>
      {{ 'COMMON_ACTIONS.SAVE_CHANGES' | translate }}
    </dpa-modal-title>
    <div class="modal-body">
      <clr-alert
        clrAlertType="warning"
        [clrAlertClosable]="false"
      >
        <clr-alert-item>
          <span class="alert-text">
            {{ 'CONNECTIONS.WORKFLOWS_USING_THIS_CONNECTION' | translate: { automations: connector?.workflowsCount } }}
          </span>
        </clr-alert-item>
      </clr-alert>
      <p>
        {{ 'CONNECTIONS.UPDATE_CONNECTION_CONFIRM_MSG' | translate }}
      </p>
    </div>
    <div class="modal-footer">
      <button
        (click)="isConfirmModalOpen = false"
        class="btn btn-outline mr-2"
      >
        {{ 'COMMON_ACTIONS.CANCEL' | translate }}
      </button>
      <button
        [clrLoading]="isLoading"
        (click)="confirmEdit()"
        class="btn btn-primary"
      >
        {{ 'COMMON_ACTIONS.SAVE' | translate }}
      </button>
    </div>
  </dpa-modal>
}
