<div class="clr-display-block m-x4">
  <dpa-local-search
    (onSearch)="searchItem.emit($event)"
    class="clr-display-block ml-x4"
    iconClass="search"
    placeHolderText="WORKFLOWS.SEARCH_PLACEHOLDER"
  ></dpa-local-search>
  <dpa-data-grid
    [pageSize]="pageSize"
    [sortOns]="sortOns"
    [loading]="loading"
    [response]="response"
    [selectable]="{ enabled: false }"
    [noResultsText]="'WORKFLOWS.NO_WORKFLOWS_FOUND' | translate"
    [attr.aria-label]="'COMMON_MESSAGES.WORKFLOWS_TABLE' | translate"
    (pageChange)="onPageChange($event)"
    (sortChange)="onSortChange($event)"
    (refreshClick)="onRefresh()"
    tabindex="0"
  >
    @if (showActions) {
      <div
        dpaDataGridSingleRowAction
        [hidden]="isSourceObjectAutomation"
        [label]="'COMMON_ACTIONS.EDIT' | translate"
        (click)="onSingleRowAction(AUTOMATION_ACTION_TYPE.EDIT, $event)"
        (keyup.enter)="onSingleRowAction(AUTOMATION_ACTION_TYPE.EDIT, $event)"
      ></div>
      <div
        dpaDataGridSingleRowAction
        [hidden]="isRunButtonHidden"
        [disabled]="isRunButtonDisabled"
        [label]="'COMMON_ACTIONS.RUN' | translate"
        (click)="onSingleRowAction(AUTOMATION_ACTION_TYPE.RUN, $event)"
        (keyup.enter)="onSingleRowAction(AUTOMATION_ACTION_TYPE.RUN, $event)"
      ></div>
      <div
        dpaDataGridSingleRowAction
        [disabled]="isToggleDisabled()"
        [hidden]="isSourceObjectAutomation"
        [labelGenerator]="getLabelGenerator()"
        (click)="onSingleRowAction(AUTOMATION_ACTION_TYPE.TOGGLE, $event)"
        (keyup.enter)="onSingleRowAction(AUTOMATION_ACTION_TYPE.TOGGLE, $event)"
      ></div>
      <div
        dpaDataGridSingleRowAction
        [hidden]="isSourceObjectAutomation"
        [label]="'COMMON_ACTIONS.RENAME' | translate"
        (click)="onSingleRowAction(AUTOMATION_ACTION_TYPE.RENAME, $event)"
        (keyup.enter)="onSingleRowAction(AUTOMATION_ACTION_TYPE.RENAME, $event)"
      ></div>
      <div
        dpaDataGridSingleRowAction
        [hidden]="isSourceObjectAutomation"
        [label]="'COMMON_ACTIONS.DUPLICATE' | translate"
        (click)="onSingleRowAction(AUTOMATION_ACTION_TYPE.COPY, $event)"
        (keyup.enter)="onSingleRowAction(AUTOMATION_ACTION_TYPE.COPY, $event)"
      ></div>
      <div
        dpaDataGridSingleRowAction
        [hidden]="isSourceObjectAutomation"
        [label]="'COMMON_ACTIONS.DELETE' | translate"
        (click)="onSingleRowAction(AUTOMATION_ACTION_TYPE.DELETE, $event)"
        (keyup.enter)="onSingleRowAction(AUTOMATION_ACTION_TYPE.DELETE, $event)"
        className="text-danger"
      ></div>
      <div
        dpaDataGridSingleRowAction
        [disabled]="!hasIncidentViewPerm"
        [hidden]="isNotSourceObjectAutomationOfType(AutomationSourceObjectType.INCIDENT)"
        [label]="'AUTOMATION_ACTIONS.VIEW_INCIDENT' | translate"
        (click)="onSingleRowAction(AUTOMATION_ACTION_TYPE.VIEW_SOURCE_OBJECT, $event)"
        (keyup.enter)="onSingleRowAction(AUTOMATION_ACTION_TYPE.VIEW_SOURCE_OBJECT, $event)"
      >
        @if (!hasIncidentViewPerm) {
          <dpa-info-tooltip
            [tooltipAriaLabel]="'COMMON_ERRORS.NO_AUTHORIZATION_CONTACT_IT' | translate"
            [tooltipText]="'COMMON_ERRORS.NO_AUTHORIZATION_CONTACT_IT' | translate"
          ></dpa-info-tooltip>
        }
      </div>
      <div
        dpaDataGridSingleRowAction
        [hidden]="isNotSourceObjectAutomationOfType(AutomationSourceObjectType.SURVEY)"
        [label]="'AUTOMATION_ACTIONS.VIEW_SURVEY' | translate"
        (click)="onSingleRowAction(AUTOMATION_ACTION_TYPE.VIEW_SOURCE_OBJECT, $event)"
        (keyup.enter)="onSingleRowAction(AUTOMATION_ACTION_TYPE.VIEW_SOURCE_OBJECT, $event)"
      ></div>
    }

    <div
      dpaDataGridColumn
      [title]="'WORKFLOWS.WORKFLOW_NAME' | translate"
      [field]="COLUMN_NAMES.byName.name"
      [sortBy]="COLUMN_NAMES.byName.name"
    >
      <ng-template
        dpaDataGridCell
        let-dataItem
      >
        <div class="automation-name">
          <dpa-ellipsis-with-tooltip>
            <a
              (click)="onNavigateToAutomation(dataItem?.id)"
              (keyup.enter)="onNavigateToAutomation(dataItem?.id)"
              class="link-normal"
            >
              {{ dataItem?.name }}
            </a>
          </dpa-ellipsis-with-tooltip>
        </div>
      </ng-template>
    </div>
    <div
      dpaDataGridColumn
      [title]="'AUTOMATION_ACTIONS.TRIGGER_AND_ACTION' | translate"
      [field]="COLUMN_NAMES.byField.serviceTypes"
      className="trigger-action-wrapper"
    >
      <ng-template
        dpaDataGridCell
        let-dataItem
        [skipEllipsisTooltip]="true"
      >
        @if (dataItem?.integration) {
          <dpa-automation-trigger-action [automation]="dataItem"></dpa-automation-trigger-action>
        }
      </ng-template>
    </div>
    <div
      dpaDataGridColumn
      [title]="'LIST_VIEW.COLUMNS.STATUS' | translate"
      [field]="COLUMN_NAMES.byName.active"
      [sortBy]="COLUMN_NAMES.byName.active"
    >
      <ng-template
        dpaDataGridCell
        let-dataItem
      >
        <dpa-automation-status-badge
          [automation]="dataItem"
          [layoutType]="LayoutType.GRID_VIEW"
        ></dpa-automation-status-badge>
      </ng-template>
    </div>
    @if (hasRunHistoryDashboardPerm$ | async) {
      <div
        dpaDataGridColumn
        [title]="'WORKFLOWS.RUN_HISTORY' | translate"
        [field]="COLUMN_NAMES.byField.runHistory"
      >
        <ng-template
          dpaDataGridCell
          let-dataItem
        >
          <dpa-automation-ratio-indicator
            [automationId]="dataItem?.id"
            [runHistoryTrend]="runHistoryTrend"
          ></dpa-automation-ratio-indicator>
        </ng-template>
      </div>
    }
    <div
      dpaDataGridColumn
      [title]="'WORKFLOWS.HAS_INCIDENT' | translate"
      [field]="COLUMN_NAMES.byField.hasIncident"
    >
      <ng-template
        dpaDataGridCell
        let-dataItem
      >
        {{
          (dataItem?.sourceObjectType === AutomationSourceObjectType.INCIDENT ? 'COMMON_MESSAGES.YES' : 'COMMON_MESSAGES.NO') | translate
        }}
      </ng-template>
    </div>
    <div
      dpaDataGridColumn
      [title]="'WORKFLOWS.HAS_SURVEY' | translate"
      [field]="COLUMN_NAMES.byField.hasSurvey"
    >
      <ng-template
        dpaDataGridCell
        let-dataItem
      >
        {{ (dataItem?.sourceObjectType === AutomationSourceObjectType.SURVEY ? 'COMMON_MESSAGES.YES' : 'COMMON_MESSAGES.NO') | translate }}
      </ng-template>
    </div>
    <div
      dpaDataGridColumn
      [title]="'COMMON_MESSAGES.LAST_RUN' | translate"
      [field]="COLUMN_NAMES.byField.lastRun"
    >
      <ng-template
        dpaDataGridCell
        let-dataItem
      >
        @if (dataItem?.lastRunDetails) {
          <span>
            <cds-icon
              [attr.shape]="dataItem?.lastRunIconShape"
              [attr.size]="14"
              [ngClass]="dataItem?.lastRunStatus"
            ></cds-icon>
            <span>
              {{ dataItem?.lastRunDetails.runDate | formattedDate: DateTimeFormat.MOMENT_MEDIUM_DATETIME_FORMAT }}
            </span>
          </span>
        } @else {
          <span>
            {{ 'COMMON_MESSAGES.NEVER' | translate }}
          </span>
        }
      </ng-template>
    </div>
    <div
      dpaDataGridColumn
      [title]="'LIST_VIEW.COLUMNS.LAST_MODIFIED' | translate"
      [field]="COLUMN_NAMES.byField.modifiedAt"
      [sortBy]="COLUMN_NAMES.byName.modified_at"
    >
      <ng-template
        dpaDataGridCell
        let-dataItem
      >
        <dpa-from-now [timestamp]="dataItem?.modifiedAt"></dpa-from-now>
      </ng-template>
    </div>
  </dpa-data-grid>
</div>
