@if (showPayloadBodyHeader()) {
  <h3>
    {{ 'CONNECTIONS.PAYLOAD_BODY' | translate }}
  </h3>
}
@if (isLegacy) {
  <dpa-fields-meta-form
    [columnLookupVariables]="columnLookupVariables"
    [formGroup]="getFormGroup(FORM_TYPE.FIELDS)"
    [isEditMode]="isEditMode"
    [metaForm]="metaForm"
    [settings]="settings"
    [searchableActionsEnabled]="searchableActionsEnabled"
    [lookupMap]="lookupMap"
    [lookupMapLoading]="lookupMapLoading"
    [nestedLookups]="nestedLookups"
    (formGroupChange)="onFormGroupChange(FORM_TYPE.FIELDS, $event)"
    (lookupChoiceChange)="onLookupChoiceChange($event)"
    (onRequestLookup)="onRequestFieldLookup($event)"
  ></dpa-fields-meta-form>
}
@if (isLegacy && isSectioned) {
  <div class="pt-x4"></div>
}
@if (isSectioned && formGroup.contains(FORM_TYPE.SECTIONS)) {
  <dpa-sections-meta-form
    [columnLookupVariables]="columnLookupVariables"
    [formGroup]="getFormGroup(FORM_TYPE.SECTIONS)"
    [isEditMode]="isEditMode"
    [metaForm]="sectionMetaForm"
    [settings]="settingsBySection"
    [searchableActionsEnabled]="searchableActionsEnabled"
    [lookupMap]="lookupMap"
    [lookupMapLoading]="lookupMapLoading"
    [nestedLookups]="nestedLookups"
    (formGroupChange)="onFormGroupChange(FORM_TYPE.SECTIONS, $event)"
    (lookupChoiceChange)="onLookupChoiceChange($event)"
    (onRequestLookup)="onRequestFieldLookup($event)"
  ></dpa-sections-meta-form>
}
