<div
  [class.expanded]="isExpanded"
  class="side-panel-wrapper"
>
  <div class="side-panel-container">
    <div class="side-panel side-panel-main">
      @if (header) {
        <div class="side-panel-header">
          <h5
            class="mt-0"
            tabindex="0"
          >
            {{ header }}
          </h5>
          @if (closable) {
            <a
              [attr.aria-label]="'COMMON_ACTIONS.CLOSE' | translate"
              (click)="onSidePanelClose()"
              (keyup.enter)="onSidePanelClose()"
              class="close-button clickable"
              role="button"
            >
              <cds-icon
                size="20"
                shape="times"
              ></cds-icon>
            </a>
          }
        </div>
      }
      <div class="side-panel-body">
        <ng-content select="dpa-side-panel-main-body"></ng-content>
      </div>
      @if (showFooter) {
        <div class="side-panel-header side-panel-footer">
          <ng-content select="dpa-side-panel-main-footer"></ng-content>
        </div>
      }
    </div>
    @if (showExpandedSidePanel) {
      <div class="side-panel side-panel-expanded">
        <div class="side-panel-header">
          <div class="expanded-header">
            <ng-content select="dpa-side-panel-expanded-header"></ng-content>
          </div>
          <dpa-tooltip
            [tooltipText]="'COMMON_ACTIONS.HIDE' | translate"
            [attr.aria-label]="'COMMON_ACTIONS.HIDE' | translate"
            [focusEnabled]="true"
            (click)="toggleSidePanel(false)"
            (keyup.enter)="toggleSidePanel(false)"
            role="button"
          >
            <cds-icon
              shape="arrow"
              direction="right"
              size="32"
            ></cds-icon>
          </dpa-tooltip>
        </div>
        <div class="side-panel-expanded-body">
          <ng-content select="dpa-side-panel-expanded-body"></ng-content>
        </div>
      </div>
    }
  </div>
</div>
