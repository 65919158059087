@if (show) {
  <div
    dpaScrollIntoView
    (click)="preventDefault($event)"
    (keyup.enter)="preventDefault($event)"
    class="all-container"
  >
    @if (showSearchTextBox) {
      <div
        [class.empty]="filterKeys.length === 0"
        class="search-box"
      >
        <div class="search-icon">
          <cds-icon shape="search" />
        </div>
        <dpa-local-search
          #localSearch
          [inputValue]="searchString"
          [showIcon]="false"
          [showReset]="showReset"
          [placeHolderText]="'COMMON_ACTIONS.SEARCH' | translate"
          (keydown)="onMenuContainerKeydown($event, true)"
          (onSearch)="onSearchText($event)"
          iconClass="search"
        />
      </div>
    }
    <div
      (click)="preventDefault($event)"
      (keyup.enter)="preventDefault($event)"
      (mousedown)="preventDefault($event)"
    >
      @if (filterKeys.length === 0) {
        <div class="empty-container">
          <div class="empty-text">
            {{ 'COMMON_MESSAGES.NO_RESULTS_FOUND' | translate }}
          </div>
        </div>
      }
      <ng-container>
        @if (filterKeys.length > 0) {
          <div
            (keydown)="onMenuContainerKeydown($event, true)"
            class="flat-view selector-container"
            tabindex="0"
          >
            <ng-container>
              <div class="form-group alpha-col-elem-container pl-x0">
                @if (!baseClassificationNotFound) {
                  <ng-container>
                    @if (baseCategoryResultNotFound) {
                      <div class="pv-x1 pl-x1">
                        {{ 'COLUMN_SELECTOR.NO_RESULT_IN_BASE_CATEGORY' | translate: { categoryLabel: activeCategory.fullLabel } }}
                      </div>
                    }
                    @if (hideExtraColumns && baseCategoryResultNotFound) {
                      <button
                        (click)="toggleHideExtraColumns($event)"
                        class="btn btn-link"
                      >
                        {{ 'COLUMN_SELECTOR.SHOW_ALL' | translate }}
                      </button>
                    }
                  </ng-container>
                }
                <clr-tree>
                  @for (entityColumns of filterKeys; track entityColumns?.name; let index = $index) {
                    <ng-container>
                      @if (index === (baseCategoryResultNotFound ? -1 : 0) || !hideExtraColumns || baseClassificationNotFound) {
                        <clr-tree-node
                          [clrExpanded]="true"
                          class="entity"
                        >
                          <span class="columns-header">
                            {{ entityColumns.label }}
                          </span>
                          <ng-container
                            *dpaDelayRender="1"
                            ngProjectAs="clr-tree-node"
                          >
                            @for (column of entityColumns.columns; track column?.attribute?.fullyQualifiedName) {
                              @if (
                                !column.isNestedAttribute &&
                                !(column?.attribute?.fullyQualifiedName | isAttributeRaw: columnToggleFilterMap)
                              ) {
                                <clr-tree-node
                                  (keyup.enter)="applySelectedKey(column)"
                                  class="adjusted-padding"
                                >
                                  <div
                                    class="col-items clr-row filtered-item"
                                    role="option"
                                  >
                                    <ng-container
                                      [ngTemplateOutlet]="attributeSelectorV2ColumnLabel"
                                      [ngTemplateOutletContext]="{ column: column }"
                                    ></ng-container>
                                  </div>
                                </clr-tree-node>
                              }
                            }
                          </ng-container>
                        </clr-tree-node>
                      }
                    </ng-container>
                  }
                </clr-tree>
              </div>
            </ng-container>
          </div>
        }
      </ng-container>
      <div class="footer-banner">
        @if (filterKeys.length > 0 && !baseClassificationNotFound) {
          <button
            (click)="toggleHideExtraColumns($event)"
            class="btn btn-link footer-link m-x0"
          >
            {{ (hideExtraColumns ? 'COLUMN_SELECTOR.SHOW_ALL' : 'COLUMN_SELECTOR.HIDE') | translate }}
          </button>
        }
        <div class="glossary-link">
          @if (filterDescriptionsUrl$ | async; as url) {
            <a
              (click)="openLink($event, url)"
              (keyup.enter)="openLink($event, url)"
              class="link-normal"
            >
              {{ 'COMMON_MESSAGES.GLOSSARY' | translate }}
              <cds-icon
                class="ml-x1"
                shape="pop-out"
              />
            </a>
          }
        </div>
      </div>
    </div>
  </div>
}
<ng-template
  #columnLabel
  let-column="column"
>
  <span
    [title]="column.tooltip"
    [class.active]="column.name === currentKey?.name"
    [dpaInnerHtmlLight]="column.styledString || column.label"
    [ngClass]="column.attribute.isNormalized ? 'clr-col-sm-8' : 'clr-col-sm-12'"
    (click)="applySelectedKey(column)"
    (keyup.enter)="applySelectedKey(column)"
    class="col-item-label text-ellipsis pr-x0"
  ></span>
  @if (column.attribute.isNormalized) {
    <span
      [class.active]="column.name === currentKey?.name"
      class="clr-col-sm-4 pr-x0"
    >
      <span class="label">
        {{ 'COMMON_MESSAGES.NORMALIZED' | translate }}
      </span>
    </span>
  }
</ng-template>

<ng-template
  #attributeSelectorV2ColumnLabel
  let-column="column"
>
  <span
    [title]="column.tooltip"
    [class.active]="isKeyActiveV2(column)"
    [dpaInnerHtmlLight]="column.styledString || column.label"
    [ngClass]="column.attribute.isNormalized ? 'clr-col-sm-8' : 'clr-col-sm-12'"
    (click)="selectKeyV2(column)"
    (keyup.enter)="selectKeyV2(column)"
    class="col-item-label text-ellipsis pr-x0"
  ></span>
  <div class="clr-col-sm-4 pr-x0">
    @if (columnToggleFilterMap[column.rawAttributeName]?.rawDataColumn) {
      <button
        (click)="toggleRawData($event, column.rawAttributeName)"
        class="label"
        role="button"
      >
        @if (!rawDataMap[column.rawAttributeName]) {
          <span>
            {{ 'COMMON_MESSAGES.NORMALIZED' | translate }}
          </span>
        } @else {
          <span>
            {{ 'COMMON_MESSAGES.RAW' | translate }}
          </span>
        }
        <cds-icon
          shape="switch"
          class="ml-x1"
        />
      </button>
    }
  </div>
</ng-template>
@if (isCustomAttributeSelectorModalOpen) {
  <dpa-custom-attribute-selector
    [parentAttributeName]="customAttributeName"
    [isMultiSelect]="false"
    (selectAttribute)="selectKeyV2($event)"
  />
}
