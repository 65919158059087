<dpa-modal
  [modalOpen]="isOpen"
  [modalSize]="ModalSize.MD"
  (onModalClose)="modalClose.emit()"
>
  @if (isLoading) {
    <dpa-modal-title>
      {{ 'CUSTOM_CONNECTION.DELETE_CONNECTOR' | translate }}
    </dpa-modal-title>
  } @else {
    <dpa-modal-title>
      <dpa-ellipsis-with-tooltip>
        {{
          connectorUsageCount
            ? ('CUSTOM_CONNECTION.CANNOT_DELETE_CONNECTOR' | translate: { connector: connector?.name })
            : ('CUSTOM_CONNECTION.DELETE_CONNECTION' | translate: { connector: connector?.name })
        }}
      </dpa-ellipsis-with-tooltip>
    </dpa-modal-title>
  }

  @if (isLoading) {
    <div class="modal-body">
      <div class="spinner-container">
        <clr-spinner clrMedium></clr-spinner>
      </div>
    </div>
  } @else {
    <div class="modal-body">
      <dpa-alert-banner [target]="AlertBannerTarget.MODAL"></dpa-alert-banner>
      @if (connectorUsageCount) {
        <clr-alert
          clrAlertType="warning"
          [clrAlertClosable]="false"
        >
          <clr-alert-item>
            <div class="alert-text">
              {{ 'CUSTOM_CONNECTION.COUNT_WORKFLOWS_IMPACTED' | translate: { count: connectorUsageCount | number } }}
            </div>
          </clr-alert-item>
        </clr-alert>
      }
      <div class="mt-x4 pl-x4">
        @if (!connectorUsageCount) {
          {{ 'CUSTOM_CONNECTION.DELETE_CUSTOM_CONNECTION_CONFIRMATION' | translate }}
        } @else {
          {{ 'CUSTOM_CONNECTION.TO_DELETE_THE_CONNECTOR' | translate }}
          <ol>
            <li>{{ 'CUSTOM_CONNECTION.REMOVE_CONNECTOR_ACTIONS_FROM_WORKFLOWS' | translate }}</li>
            <li>{{ 'CUSTOM_CONNECTION.UPDATE_THE_WORKFLOW_WITH_A_NEW_ACTION' | translate }}</li>
            <li>{{ 'CUSTOM_CONNECTION.TEST_TO_VALIDATE_THE_UPDATES_TO_ALL_WORKFLOWS' | translate }}</li>
          </ol>
        }
      </div>
    </div>
    <div class="modal-footer">
      <button
        (click)="modalClose.emit()"
        type="button"
        class="btn btn-outline"
      >
        {{ 'COMMON_ACTIONS.CLOSE' | translate }}
      </button>
      @if (!connectorUsageCount) {
        <button
          [clrLoading]="isLoading"
          (click)="delete.emit()"
          type="button"
          class="btn btn-danger"
        >
          {{ 'COMMON_ACTIONS.DELETE' | translate }}
        </button>
      } @else {
        <button
          [clrLoading]="isLoading"
          (click)="viewWorkflows.emit()"
          type="button"
          class="btn btn-primary"
        >
          {{ 'CUSTOM_CONNECTION.VIEW_WORKFLOWS' | translate }}
        </button>
      }
    </div>
  }
</dpa-modal>
