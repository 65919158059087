<dpa-template-search
  [templateType]="templateType"
  [templates]="templates$ | async"
  [tags]="tags"
  [selectedTag]="selectedTag"
  [customCardBlock]="customCardBlock"
  [templateTableColumns]="tableColumns"
  [noResultsTemplate]="noResultsTemplate"
  [showCustomContentTemplate]="showCustomButton"
  [bookmarksEnabled]="false"
  [actions]="actions"
  [dropdownActions]="dropdownActions"
  [cellTemplatesByColumnValue]="{ createdByDetails: createdByDetailsTemplate }"
  [tableName]="'COMMON_MESSAGES.REPORT_TEMPLATES_TABLE' | translate"
  (clickCustomTemplate)="onClickCustomTemplate()"
></dpa-template-search>
<ng-template
  #createdByDetailsTemplate
  let-dataItem="dataItem"
  let-dataGridColumn="dataGridColumn"
>
  <div>{{ dataItem[dataGridColumn.value] | userInfo }}</div>
</ng-template>
<ng-template
  #customCardBlock
  let-template="template"
>
  <div class="card-block">
    <dpa-card-block-row>
      {{ 'COMMON_MESSAGES.ADDED_BY_NAME' | translate: { name: template.createdByDetails | userInfo } }}
    </dpa-card-block-row>
    <dpa-card-block-row>
      {{
        'LIST_VIEW.COLUMNS.LAST_MODIFIED_WHEN'
          | translate: { when: template.modifiedAt | formattedDate: DateTimeFormat.MOMENT_MEDIUM_DATETIME_FORMAT }
      }}
    </dpa-card-block-row>
    @if (showDataType(template)) {
      <dpa-card-block-row>
        {{ 'COMMON_MESSAGES.DATA_TYPE' | translate }}:
        {{ 'REPORT_BUILDER.REPORT_TYPE_' + REPORT_TYPE[template.reportType] | translate }}
      </dpa-card-block-row>
    }
  </div>
</ng-template>

<ng-template #noResultsTemplate>
  @if ((isTemplatesLoading$ | async) === false) {
    <dpa-empty-state
      [descriptionTemplate]="noResultsDescriptionTemplate"
      bgImageClass="manage-templates-empty-state empty-state-img-small"
    ></dpa-empty-state>
  }
</ng-template>

<ng-template #noResultsDescriptionTemplate>
  <h3>
    {{ 'CUSTOM_REPORTS_ACTIONS.MANGE_TEMPLATES_NO_TEMPLATES_TITLE_DESCRIPTION' | translate }}
  </h3>
  <p>
    {{ 'CUSTOM_REPORTS_ACTIONS.MANGE_TEMPLATES_NO_TEMPLATES_SUBTEXT_DESCRIPTION' | translate }}
  </p>
  <button
    (click)="onAddTemplate()"
    type="button"
    class="btn btn-primary mt-4"
  >
    {{ 'CUSTOM_REPORTS_ACTIONS.ADD_TEMPLATE' | translate }}
  </button>
</ng-template>
