@if (runHistory) {
  <dpa-ratio-indicator
    [ratios]="[
      {
        count: runHistory.COMPLETED,
        color: RatioIndicatorColors.SUCCESS,
        label: 'WORKFLOWS.RUN_HISTORY_ACTION_STATUS_COMPLETED' | translate
      },
      { count: runHistory.ERROR, color: RatioIndicatorColors.ERROR, label: 'WORKFLOWS.RUN_HISTORY_ACTION_STATUS_ERROR' | translate },
      { count: runHistory.ACTIVE, color: RatioIndicatorColors.ACTIVE, label: 'WORKFLOWS.RUN_HISTORY_ACTION_STATUS_ACTIVE' | translate }
    ]"
  ></dpa-ratio-indicator>
}
