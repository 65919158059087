<dpa-data-grid
  [pageSize]="pageSize"
  [loading]="loading"
  [response]="response"
  [sortOns]="sortOns"
  [selectable]="{ enabled: false, single: false }"
  [attr.aria-label]="'COMMON_MESSAGES.WORKFLOW_CONNECTORS_TABLE' | translate"
  (sortChange)="sortChange.emit($event)"
  (pageChange)="pageChange.emit($event)"
  (refreshClick)="refreshClick.emit()"
  tabindex="0"
>
  @if (showActions) {
    <div
      dpaDataGridSingleRowAction
      [label]="'CONNECTORS.SET_UP' | translate"
      [hidden]="isActionHidden(CONNECTOR_ACTION_TYPE.SET_UP)"
      [disabled]="isActionDisabled(CONNECTOR_ACTION_TYPE.SET_UP)"
      (click)="onAction(CONNECTOR_ACTION_TYPE.SET_UP, $event)"
      (keyup.enter)="onAction(CONNECTOR_ACTION_TYPE.SET_UP, $event)"
    ></div>
    <div
      dpaDataGridSingleRowAction
      [label]="'COMMON_ACTIONS.EDIT' | translate"
      [hidden]="isActionHidden(CONNECTOR_ACTION_TYPE.EDIT)"
      [disabled]="isActionDisabled(CONNECTOR_ACTION_TYPE.EDIT)"
      (click)="onAction(CONNECTOR_ACTION_TYPE.EDIT, $event)"
      (keyup.enter)="onAction(CONNECTOR_ACTION_TYPE.EDIT, $event)"
    ></div>
    <div
      dpaDataGridSingleRowAction
      [label]="'COMMON_ACTIONS.RENAME' | translate"
      [hidden]="isActionHidden(CONNECTOR_ACTION_TYPE.RENAME)"
      [disabled]="isActionDisabled(CONNECTOR_ACTION_TYPE.RENAME)"
      (click)="onAction(CONNECTOR_ACTION_TYPE.RENAME, $event)"
      (keyup.enter)="onAction(CONNECTOR_ACTION_TYPE.RENAME, $event)"
    ></div>
    <div
      dpaDataGridSingleRowAction
      [label]="'COMMON_ACTIONS.DELETE' | translate"
      (click)="onAction(CONNECTOR_ACTION_TYPE.DELETE, $event)"
      (keyup.enter)="onAction(CONNECTOR_ACTION_TYPE.DELETE, $event)"
      className="text-danger"
    ></div>
  }
  <div
    dpaDataGridColumn
    [title]="'COMMON_MESSAGES.NAME' | translate"
    [field]="COLUMN_NAMES.byName.name"
    [sortBy]="COLUMN_NAMES.byName.name"
  >
    <ng-template
      dpaDataGridCell
      let-dataItem
    >
      <div class="connection-name">
        <div
          [style.background-image]="dataItem?.iconBackgroundImage"
          class="connector-icon"
        ></div>
        <a
          (click)="onAction(CONNECTOR_ACTION_TYPE.VIEW, dataItem)"
          (keyup.enter)="onAction(CONNECTOR_ACTION_TYPE.VIEW, dataItem)"
          class="link-normal ml-x2"
        >
          {{ dataItem?.name }}
        </a>
      </div>
    </ng-template>
  </div>
  <div
    dpaDataGridColumn
    [title]="'INTEGRATIONS.COMMON.CONNECTION_STATUS' | translate"
    [field]="COLUMN_NAMES.byField.status"
  >
    <ng-template
      dpaDataGridCell
      let-dataItem
    >
      @if (!dataItem?.isAuthorized) {
        <a
          (click)="onAction(CONNECTOR_ACTION_TYPE.SET_UP, dataItem)"
          class="link-normal"
        >
          {{ 'CONNECTIONS.SET_UP' | translate }}
        </a>
      } @else {
        <cds-icon
          [attr.shape]="dataItem?.isAuthorized ? 'success-standard' : 'error-standard'"
          [attr.status]="dataItem?.isAuthorized ? 'success' : 'green'"
          class="mr-x1"
          solid
        ></cds-icon>
        <span>
          {{ connectorStatusKeyByStatus[dataItem?.status] | translate }}
        </span>
      }
    </ng-template>
  </div>
  <div
    dpaDataGridColumn
    [title]="'INTEGRATIONS.COMMON.STATUS_TIME' | translate"
    [field]="COLUMN_NAMES.byField.statusTime"
  >
    <ng-template
      dpaDataGridCell
      let-dataItem
    >
      <span>
        {{ (dataItem?.statusTime | formattedDate: DateTimeFormat.MOMENT_MEDIUM_DATETIME_FORMAT) || ('COMMON_MESSAGES.NA' | translate) }}
      </span>
    </ng-template>
  </div>
  <div
    dpaDataGridColumn
    [title]="'INTEGRATIONS.COMMON.LAST_MODIFIED' | translate"
    [field]="COLUMN_NAMES.byField.modifiedAt"
  >
    <ng-template
      dpaDataGridCell
      let-dataItem
    >
      <span>
        {{ (dataItem?.modifiedAt | formattedDate: DateTimeFormat.MOMENT_MEDIUM_DATETIME_FORMAT) || ('COMMON_MESSAGES.NA' | translate) }}
      </span>
    </ng-template>
  </div>
  <div
    dpaDataGridColumn
    [title]="'COMMON_MESSAGES.WORKFLOWS' | translate"
    [field]="COLUMN_NAMES.byField.totalWorkflows"
  >
    <ng-template
      dpaDataGridCell
      let-dataItem
    >
      <span>
        {{ dataItem?.isMissingWorkflowsCount ? ('COMMON_MESSAGES.NA' | translate) : dataItem?.workflowsCount }}
      </span>
    </ng-template>
  </div>
</dpa-data-grid>
