<div
  *ngLet="widgetData$ | async as widgetData"
  dpaScrollIntersectionChild
  [ngClass]="customCardBorder"
  (onIntersectionChange)="onIntersectionChange($event)"
  class="card m-x0"
>
  @if (!showOnlyWidget) {
    <div class="card-header">
      <div
        [ngClass]="isUserBookmarksEnabled ? 'pr-x6' : 'pr-x4'"
        class="card-header-content"
      >
        <dpa-ellipsis-with-tooltip>
          <span
            [dpaInnerHtmlLight]="styledString || widget.name"
            class="matching-text"
          ></span>
        </dpa-ellipsis-with-tooltip>
        <div class="right-side-icons">
          <dpa-widget-incremental-loading-status
            [showAsSpinner]="true"
            [trackingIds]="trackingIds"
          ></dpa-widget-incremental-loading-status>
          <dpa-tooltip-viewer [tooltip]="tooltip"></dpa-tooltip-viewer>
          @if ((drilldownEvents$ | async)?.length) {
            <dpa-simple-drilldown-navigator
              (clearDrilldown)="clearDrilldown()"
              (popDrilldown)="popDrilldown()"
              class="simple-drilldown"
            ></dpa-simple-drilldown-navigator>
          }
        </div>
        <!-- This is positioned absolutely in the upper right hand corner  -->
        @if (isBookmarkEnabled()) {
          <dpa-tooltip
            [tooltipText]="
              (activeBookmarkIdsSet.has(widget.id) ? 'BOOKMARKS.REMOVE_FROM_BOOKMARKS' : 'BOOKMARKS.ADD_TO_BOOKMARKS') | translate
            "
            [attr.aria-label]="
              (activeBookmarkIdsSet.has(widget.id) ? 'BOOKMARKS.REMOVE_FROM_BOOKMARKS' : 'BOOKMARKS.ADD_TO_BOOKMARKS') | translate
            "
            [focusEnabled]="true"
            (click)="toggleBookmark()"
            (keyup.enter)="toggleBookmark()"
            class="bookmark-wrapper"
            role="tooltip"
          >
            <cds-icon
              [attr.solid]="activeBookmarkIdsSet.has(widget.id)"
              shape="bookmark"
              size="20"
            ></cds-icon>
          </dpa-tooltip>
        }
        <button
          (click)="expandOrCollapseControlBar()"
          class="btn btn-link btn-sm btn-icon m-x0 widget-dropdown-indicator-wrapper"
        >
          <cds-icon
            size="20"
            shape="filter-2"
            class="dropdown-indicator"
          ></cds-icon>
        </button>
      </div>
    </div>
  }
  <div
    [class.only-widget]="showOnlyWidget"
    class="card-block p-x0"
  >
    <dpa-widget-incremental-loading-status
      [showAsProgressBar]="true"
      [trackingIds]="trackingIds"
    ></dpa-widget-incremental-loading-status>
    @if (widget?.id; as widgetId) {
      <dpa-fluid-height
        [isExpanded]="isControlBarExpanded"
        [showOverflowWhenNotAnimating]="true"
      >
        @if (isControlBarExpanded$ | async) {
          <div class="card-control-bar">
            <dpa-widget-control-bar
              [widgetId]="widgetId"
              [trend]="widgetData?.result"
              [splitCol]="true"
              [hasFullAccess]="hasFullPerm"
              [isCrossCategory]="isWidgetJoinEnabled"
            ></dpa-widget-control-bar>
          </div>
        }
      </dpa-fluid-height>
    }
    @if (webError$ | async; as webError) {
      <dpa-web-error
        [webError]="webError"
        class="m-x4"
      ></dpa-web-error>
    }
    @if (chartType$ | async; as chartType) {
      <dpa-standard-chart
        #standardChart
        [loading]="!widgetData?.result"
        [chartData]="widgetData?.result"
        [overlayChartData]="(overlayData$ | async)?.result"
        [focusedSeries]="focusedSeries$ | async"
        [activeChartType]="chartType"
        [showSeriesNames]="true"
        [showDetailsLink]="!widgetData?.result?.trendDefinition.isSnapshotPeriodical"
        [showYAxisLabel]="getShowYAxisLabel()"
        [showDataLabel]="showDataLabel"
        [showLabels]="autoLabelLegend"
        [rangeFilter]="rangeFilter$ | async"
        [isInvertMode]="isInvertMode$ | async"
        [colorSchemas]="colorSchemas$ | async"
        [class.only-widget]="showOnlyWidget"
        (drilldown)="pushDrilldownEvent($event)"
        (tooltipChange)="onTooltipChange($event)"
        class="standard-chart"
      ></dpa-standard-chart>
    }
  </div>
  @if (!showOnlyWidget) {
    <div class="card-footer">
      <div class="clr-row clr-flex-items-sm-middle full-width mh-x0">
        <div class="clr-col-sm ph-x0">
          <button
            [attr.aria-label]="'DASHBOARD_ACTIONS.VIEW_DASHBOARD_WIDGET_ARIA_LABEL' | translate: { name: widget.name }"
            [disabled]="!widgetData?.result"
            (click)="goToWidgetDetail()"
            type="button"
            class="btn btn-link"
          >
            {{ 'COMMON_ACTIONS.VIEW' | translate }}
          </button>
        </div>
        @if (isDeprecated) {
          <div class="label label-warning mt-1">
            {{ 'CUSTOM_DASHBOARD.DEPRECATED' | translate }}
          </div>
        }
        <dpa-widget-tag
          [trend]="widgetData?.result"
          [maxNumTags]="1"
        ></dpa-widget-tag>
        @if ((hasWidgetWritePerm$ | async) && dashboard?.isOwnerOrIsShared) {
          <div>
            <clr-dropdown>
              <button
                clrDropdownTrigger
                type="button"
                class="btn-sm btn-link ph-0"
              >
                <cds-icon
                  shape="ellipsis-horizontal"
                  size="24"
                ></cds-icon>
              </button>
              <clr-dropdown-menu
                *clrIfOpen
                [clrPosition]="dropdownPosition"
              >
                <button
                  clrDropdownItem
                  (click)="requestInfoWidget()"
                  data-action-type="INFO"
                  type="button"
                >
                  {{ 'COMMON_ACTIONS.INFO' | translate }}
                </button>
                @if (hasFullPerm) {
                  @if (!isDeprecated) {
                    <button
                      clrDropdownItem
                      (click)="requestUpdateWidget(widget)"
                      data-action-type="EDIT"
                      type="button"
                    >
                      {{ 'COMMON_ACTIONS.EDIT' | translate }}
                    </button>
                  }
                  @if (isWidgetEditThemeAvailable) {
                    <button
                      clrDropdownItem
                      (click)="onEditTheme()"
                      type="button"
                    >
                      {{ 'COMMON_ACTIONS.EDIT_THEME' | translate }}
                    </button>
                  }
                }
                @if (isEditRangeAvailable$ | async) {
                  <button
                    clrDropdownItem
                    (click)="openEditRangeDialog(true)"
                    data-action-type="EDIT_RANGES"
                    type="button"
                  >
                    {{ 'WIDGET_CUSTOMIZE.EDIT_RANGES' | translate }}
                  </button>
                }
                @if (hasFullPerm) {
                  @if (widget.trend.trendDefinition.automationEnabled && (hasAutomationWritePerm$ | async)) {
                    <button
                      clrDropdownItem
                      [disabled]="!widget.trend.trendDefinition.isCategoryAutomationSupported"
                      (click)="requestAutomateWidget()"
                      data-action-type="AUTOMATE"
                      type="button"
                    >
                      {{ 'COMMON_ACTIONS.AUTOMATE' | translate }}
                    </button>
                  }
                }
                <div
                  class="dropdown-divider"
                  role="separator"
                ></div>
                <button
                  clrDropdownItem
                  (click)="requestRefreshWidget(widget)"
                  data-action-type="REFRESH"
                  type="button"
                >
                  {{ 'COMMON_ACTIONS.REFRESH' | translate }}
                </button>
                <button
                  clrDropdownItem
                  (click)="exportAsCsv()"
                  data-action-type="EXPORT_AS_CSV"
                  type="button"
                >
                  {{ 'COMMON_ACTIONS.EXPORT_AS_CSV' | translate }}
                </button>
                @if (!isDeprecated) {
                  <button
                    clrDropdownItem
                    (click)="requestCopyToWidget()"
                    data-action-type="COPY_TO"
                    type="button"
                  >
                    {{ 'COMMON_ACTIONS.COPY_TO' | translate }}
                  </button>
                }
                @if (isBookmarkEnabled()) {
                  <button
                    clrDropdownItem
                    [attr.data-action-type]="activeBookmarkIdsSet.has(widget.id) ? 'REMOVE_FROM_BOOKMARKS' : 'ADD_TO_BOOKMARKS'"
                    (click)="toggleBookmark()"
                    type="button"
                  >
                    {{
                      (activeBookmarkIdsSet.has(widget.id) ? 'BOOKMARKS.REMOVE_FROM_BOOKMARKS' : 'BOOKMARKS.ADD_TO_BOOKMARKS') | translate
                    }}
                  </button>
                }
                @if (hasFullPerm && !isDeprecated) {
                  <div
                    class="dropdown-divider"
                    role="separator"
                  ></div>
                  <button
                    clrDropdownItem
                    (click)="requestDuplicateWidget()"
                    data-action-type="DUPLICATE"
                    type="button"
                  >
                    {{ 'COMMON_ACTIONS.DUPLICATE' | translate }}
                  </button>
                  <button
                    clrDropdownItem
                    (click)="requestRenameWidget()"
                    data-action-type="RENAME"
                    type="button"
                  >
                    {{ 'COMMON_ACTIONS.RENAME' | translate }}
                  </button>
                  <button
                    clrDropdownItem
                    (click)="setDashboardThumbnail()"
                    data-action-type="SET_AS_THUMBNAIL"
                    type="button"
                  >
                    {{ 'DASHBOARD_ACTIONS.SET_AS_THUMBNAIL' | translate }}
                  </button>
                  <div
                    class="dropdown-divider"
                    role="separator"
                  ></div>
                }
                @if (hasFullPerm) {
                  <button
                    clrDropdownItem
                    (click)="requestDeleteWidget()"
                    data-action-type="DELETE"
                    class="text-danger"
                    type="button"
                  >
                    {{ 'COMMON_ACTIONS.DELETE' | translate }}
                  </button>
                }
              </clr-dropdown-menu>
            </clr-dropdown>
          </div>
        }
      </div>
    </div>
  }
</div>
