@if ((irrecoverableActions$ | async)?.length) {
  <clr-alert
    [clrAlertType]="'warning'"
    [clrAlertClosable]="false"
  >
    <clr-alert-item class="alert-item">
      <span class="alert-text">
        {{ 'WORKFLOWS.RECOVERABLE_MESSAGE' | translate: { count: (irrecoverableActions$ | async)?.length } }}
      </span>
    </clr-alert-item>
  </clr-alert>
}

<div class="actions-list">
  @for (action of irrecoverableActions$ | async; track action; let actionIndex = $index) {
    <div>
      <div
        (click)="toggleDesc(actionIndex)"
        (keyup.enter)="toggleDesc(actionIndex)"
        class="action-label"
      >
        <cds-icon
          [attr.direction]="toggleDescStatus[actionIndex] ? 'down' : 'right'"
          shape="angle"
        ></cds-icon>
        {{ action.name }}
      </div>
      @if (toggleDescStatus[actionIndex]) {
        <div class="action-unrecoverable-message">
          {{ action.metadata.unrecoverableMessage }}
        </div>
      }
    </div>
  }
</div>
