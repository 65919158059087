<dpa-card
  [header]="connector?.name"
  [description]="connector?.description"
  [descriptionMaxLines]="3"
  class="data-source-card"
>
  <dpa-card-icon>
    <div
      [style.background-image]="connector?.iconBackgroundImage"
      class="trigger-action connection-icon"
    ></div>
  </dpa-card-icon>
  @if (connector?.isConfigured) {
    <dpa-card-block>
      <dpa-card-block-row>
        <div class="connection-status">
          <span class="mr-x1">{{ 'CONNECTIONS.STATUS' | translate }}:</span>
          <cds-icon
            [attr.shape]="connector?.isAuthorized ? 'success-standard' : 'error-standard'"
            [attr.status]="connector?.isAuthorized ? 'success' : 'danger'"
            solid
          ></cds-icon>
          <span>{{ (connector?.isAuthorized ? 'CONNECTIONS.STATUS_AUTHORIZED' : 'CONNECTIONS.FAILED_TO_CONNECT') | translate }}</span>
        </div>
      </dpa-card-block-row>
      <dpa-card-block-row>
        {{ 'CONNECTIONS.CURRENTLY_USED_IN' | translate }}
        {{
          connector.isMissingWorkflowsCount
            ? ('COMMON_MESSAGES.NA' | translate)
            : ('CONNECTIONS.COUNT_WORKFLOWS' | translate: { automationCount: connector?.workflowsCount })
        }}
      </dpa-card-block-row>
      @if (connector?.modifiedAt) {
        <dpa-card-block-row>
          <dpa-from-now
            [formatString]="'CONNECTIONS.MODIFIED_WHEN'"
            [timestamp]="connector?.modifiedAt"
          ></dpa-from-now>
        </dpa-card-block-row>
      }
    </dpa-card-block>
  }
  <dpa-card-footer>
    <dpa-card-footer-button>
      @if (connector?.isConfigured) {
        <button
          (click)="onAction(ConnectorActionType.VIEW)"
          class="btn btn-link"
        >
          {{ 'COMMON_ACTIONS.VIEW' | translate }}
        </button>
      } @else {
        <button
          (click)="onAction(ConnectorActionType.SET_UP)"
          class="btn btn-link"
        >
          {{ 'COMMON_ACTIONS.SET_UP' | translate }}
        </button>
      }
    </dpa-card-footer-button>
    @if (showActions && connector.configRequired) {
      <dpa-card-footer-dropdown [dropdownOptions]="cardFooterDropdownOptions"></dpa-card-footer-dropdown>
    }
  </dpa-card-footer>
</dpa-card>
