@if (copyWidgetDialogOpen$ | async) {
  <dpa-modal
    [modalOpen]="copyWidgetDialogOpen$ | async"
    (onModalClose)="closeModal()"
  >
    <dpa-modal-title>
      {{ 'DASHBOARD_ACTIONS.COPY_WIDGET_LABEL' | translate }}
    </dpa-modal-title>
    <div class="modal-body type-head-box">
      <div class="container">
        <form
          [formGroup]="copyWidgetForm"
          class="clr-form clr-form-horizontal"
        >
          @if (copyWidgetDialogOpen$ | async) {
            <dpa-alert-banner
              [target]="AlertBannerTarget.MODAL"
              class="bad-request-alert"
            ></dpa-alert-banner>
          }
          <div class="form-group">
            <dpa-inline-edit
              [autofocus]="true"
              [isInvalid]="isRequiredWarningVisible('widgetName')"
              [invalidTooltip]="'FORM_VALIDATION.REQUIRED_FIELD' | translate"
              [placeholder]="'WIDGET_CUSTOMIZE.WIDGET_CHART_TITLE' | translate"
              [isRequired]="true"
              formControlName="widgetName"
              size="lg"
            ></dpa-inline-edit>
            <dpa-inline-edit
              [placeholder]="'COMMON_MESSAGES.ADD_DESCRIPTION_OPTIONAL' | translate"
              formControlName="widgetDescription"
            ></dpa-inline-edit>
          </div>
          <dpa-dashboard-selector
            (dashboardFormChange)="onDashboardFormChange($event)"
            (toggleNewDashboard)="onToggleNewDashboard($event)"
          ></dpa-dashboard-selector>
        </form>
      </div>
    </div>
    <div class="modal-footer pt-x0">
      <button
        (click)="closeModal()"
        class="btn btn-outline"
      >
        {{ 'COMMON_ACTIONS.CANCEL' | translate }}
      </button>
      <button
        [disabled]="dashboardForm?.invalid || copyWidgetForm?.invalid"
        (click)="confirmCopyWidget()"
        type="button"
        class="btn btn-primary"
      >
        @if (isNewDashboard) {
          <div>
            {{ 'DASHBOARD_ACTIONS.CREATE_NEW_DASHBOARD' | translate }}
          </div>
        } @else {
          {{ 'COMMON_ACTIONS.COPY' | translate }}
        }
      </button>
    </div>
  </dpa-modal>
}
